import React from 'react'
import { Cell, Column } from 'react-table'
import { MissionsFilters } from '../../store/filters'
import StandardCell from '../table/standard-cell'
import { Mission, MissionWithSummaries, MissionWithSummary } from '../../api/missions'
import { Element, Icon } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { formatCost } from '../../utils/finance'
import {
  MissionAbandonedMessage,
  MissionIsCancelledMessage,
  MissionIsPreBilledMessage,
  MissionIsValidatedMessage,
  MissionWeekIsValidatedMessage,
} from '../missions/mission-messages'
import {
  allDaysOfCurrentWeek,
  allDaysOfInterval,
  formatDay,
  getWeek,
  getWeekYear,
} from '../../utils/date'
import { WorkPeriodSummaryCell, workPeriodSummaryHeaders } from '../table/summary-cell'
import { missionSummaryTotalsHeaders } from '../table/summary-cell'
import Protected, { isProtectedFor } from '../protected/protected'
import { MissionSummaryCell } from '../table/summary-cell'
import { missionSummaryCostsHeaders } from '../table/summary-cell'
import Table from '../table'
import useStore from '../../store'
import MissionCancelButton from '../missions/cancel-button'
import { EyeIcon } from '@heroicons/react/outline'
import { InfoIcon } from '../icons'
import { sortBy, upperFirst } from 'lodash'
import { trimTextIfLongerThan } from '../../utils/texts'
import MissionWeekValidateButton from '../missions/week-validate-button'
import { getUserLabel } from '../../utils/users'

interface SummaryTableProps {
  missions: MissionWithSummary[]
  setCurrentMissionToValidate?: React.Dispatch<any>
  setCurrentWorkPeriodToValidate?: React.Dispatch<any>
  setCurrentMissionToCancel?: React.Dispatch<any>
  summaryMinimalMode?: MissionsFilters['summaryMinimalMode']
  displayDay?: Date
  start?: Date
  end?: Date
  hideActions?: boolean
}

const SummaryTable: React.FC<SummaryTableProps> = ({
  missions,
  setCurrentMissionToValidate,
  setCurrentWorkPeriodToValidate,
  setCurrentMissionToCancel,
  summaryMinimalMode,
  displayDay,
  start,
  end,
  hideActions,
}) => {
  const tableColumns = useColumns(
    setCurrentMissionToValidate,
    setCurrentWorkPeriodToValidate,
    setCurrentMissionToCancel,
    summaryMinimalMode,
    displayDay,
    start,
    end,
    hideActions,
  )

  return (
    <Table
      columns={tableColumns}
      data={sortBy(missions, ['user._id', 'interimAgency._id'])}
      noDataMessage="Aucune mission à afficher"
      className="ml-2"
    />
  )
}

const useColumns = (
  setCurrentMissionToValidate?: React.Dispatch<any>,
  setCurrentWorkPeriodToValidate?: React.Dispatch<any>,
  setCurrentMissionToCancel?: React.Dispatch<any>,
  summaryMinimalMode?: MissionsFilters['summaryMinimalMode'],
  displayDay?: Date,
  start?: Date,
  end?: Date,
  hideActions?: boolean,
): Column[] => {
  const currentUserRole = useStore(state => state.session.currentUserRole)

  return React.useMemo(() => {
    const removeIfMinimalSummaryMode = (column: Column) => {
      if (summaryMinimalMode !== 'minimal') return true
      // We are in minimal Summary Mode
      return !(
        column.Header === 'Jour' ||
        column.Header === 'Pause' ||
        column.Header === 'Nuit' ||
        column.Header === 'Panier' ||
        column.Header === 'Supp' ||
        column.Header === '+25' ||
        column.Header === '+50' ||
        column.Header === 'RC'
      )
    }

    const isSameUserThanPreviousRow = (data: MissionWithSummary[], rowIndex: number): boolean =>
      data[rowIndex - 1]?.user?._id === data[rowIndex]?.user?._id &&
      data[rowIndex - 1]?.interimAgency?._id === data[rowIndex]?.interimAgency?._id
    const countRowsForUser = (missions: any[], userId: string, interimAgencyId: string): number =>
      missions.filter(
        mission => mission.user._id === userId && mission.interimAgency?._id === interimAgencyId,
      ).length || 0

    return [
      {
        Header: ' ',
        className: 'sticky',
        columns: [
          {
            Header: 'Nom',
            accessor: 'user.firstName',
            rowSpan: 2,
            className: 'sticky table-section-start table-section-end-sticky has-background-white',
            isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              isSameUserThanPreviousRow(data, cell.row.index),
            getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
              countRowsForUser(
                data,
                cell.row.original.user._id as string,
                cell.row.original.interimAgency?._id as string,
              ),

            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              if (isSameUserThanPreviousRow(data.data, data.row.index)) return null

              return (
                <StandardCell>
                  {!isSameUserThanPreviousRow(data.data, data.row.index) && (
                    <Element textWeight="bold">{getUserLabel(mission.user)}</Element>
                  )}
                </StandardCell>
              )
            },
          },
        ],
      },
      {
        Header: 'Mission',
        columns: [
          {
            Header: currentUserRole === 'employerMember' ? 'Agence' : 'Employeur',
            accessor: currentUserRole === 'employerMember' ? 'interimAgency.name' : 'employer.name',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element renderAs={'span'} textColor="dark" textWeight="semibold">
                    {currentUserRole === 'employerMember'
                      ? mission.interimAgency?.name
                      : mission.employer.name}
                  </Element>
                </StandardCell>
              )
            },
          },
          {
            Header: 'Service',
            accessor: 'service',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element renderAs={'span'} textColor="dark" textWeight="semibold">
                    {trimTextIfLongerThan(upperFirst(mission.service), 6)}
                  </Element>
                </StandardCell>
              )
            },
          },
          {
            Header: 'Poste',
            accessor: 'jobTitle.name',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element renderAs={'span'} textColor="dark" textWeight="semibold">
                    {trimTextIfLongerThan(upperFirst(mission.jobTitle.name), 6)}
                  </Element>
                </StandardCell>
              )
            },
          },
          {
            Header: '€/h',
            accessor: 'interimAgency',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Element
                    data-tooltip={
                      mission.interimAgency &&
                      `${mission.costs.officialHourlyCost} € (smic) * ${mission.costs.interimAgencyCostCoefficient} (coef)`
                    }
                  >
                    {formatCost(
                      mission.costs.interimAgencyCostCoefficient * mission.costs.officialHourlyCost,
                    )}
                  </Element>
                </StandardCell>
              )
            },
          },
        ],
      },
      {
        Header: '',
        id: 'leftIsValidatedLevel1',
        columns: [
          {
            Header: (
              <Icon data-tooltip={'Voir la mission'} className="has-tooltip-left" size="small">
                <EyeIcon />
              </Icon>
            ),
            accessor: 'link',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <Element renderAs={Link} to={`/missions/${mission._id}`} textWeight="bold">
                  <Icon data-tooltip={'Voir la mission'} className="has-tooltip-left" size="small">
                    <EyeIcon />
                  </Icon>
                </Element>
              )
            },
          },
        ],
      },
      ...(displayDay
        ? [displayDay]
        : start && end
        ? allDaysOfInterval(new Date(start), new Date(end))
        : allDaysOfCurrentWeek
      ).map((day: Date, index) => ({
        Header: formatDay(day),
        accessor: 'day' + index,
        columns: workPeriodSummaryHeaders
          .map(workPeriodSummaryHeader => ({
            Header: workPeriodSummaryHeader.label,
            id: day + workPeriodSummaryHeader.key,
            className: (() => {
              const classNames = []
              if (workPeriodSummaryHeader.key === 'start.counted')
                classNames.push('table-section-start')

              if (displayDay && workPeriodSummaryHeader.key === 'validation')
                classNames.push('table-section-end')
              return classNames.join(' ')
            })(),
            Cell: (data: any) => {
              return (
                <WorkPeriodSummaryCell
                  data={data}
                  day={day}
                  workPeriodSummaryHeader={workPeriodSummaryHeader}
                  validationAction={setCurrentWorkPeriodToValidate}
                />
              )
            },
          }))
          .filter(
            column =>
              !(column.Header === 'Valider' && (isProtectedFor(['employerMember']) || hideActions)),
          )
          .filter(removeIfMinimalSummaryMode),
      })),
      !displayDay && {
        Header: () => (
          <Element display="flex">
            Totaux
            {!hideActions && (
              <Icon
                size="small"
                color="primary"
                data-tooltip="Ce total est basé sur les filtres utilisés. Il peut ne pas être représentatif du total complet de la semaine."
                className="has-tooltip-multiline has-tooltip-bottom"
              >
                <InfoIcon />
              </Icon>
            )}
          </Element>
        ),
        id: 'Totaux',
        columns: missionSummaryTotalsHeaders
          .map(missionSummaryTotalHeader => {
            return {
              Header: missionSummaryTotalHeader.label,
              id: 'totals.' + missionSummaryTotalHeader.key,
              className: (() => {
                const classNames = []
                if (missionSummaryTotalHeader.key === 'durations.worked.days')
                  classNames.push('table-section-start')
                if (missionSummaryTotalHeader.key === 'durations.rc')
                  classNames.push('table-section-end')
                return classNames.join(' ')
              })(),
              isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                isSameUserThanPreviousRow(data, cell.row.index),
              getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                countRowsForUser(
                  data,
                  cell.row.original.user._id as string,
                  cell.row.original.interimAgency?._id as string,
                ),
              Cell: (data: any) => (
                <MissionSummaryCell data={data} missionSummaryHeader={missionSummaryTotalHeader} />
              ),
            }
          })
          .filter(removeIfMinimalSummaryMode),
      },
      !displayDay && {
        Header: () => (
          <Element display="flex">
            Coûts
            {!hideActions && (
              <Icon
                size="small"
                color="primary"
                data-tooltip="Ce total est basé sur les filtres utilisés. Il peut ne pas être représentatif du total complet de la semaine."
                className="has-tooltip-multiline has-tooltip-bottom"
              >
                <InfoIcon />
              </Icon>
            )}
          </Element>
        ),
        id: 'Coûts',
        columns: missionSummaryCostsHeaders
          .map(missionSummaryCostHeader => {
            return {
              Header: missionSummaryCostHeader.label,
              id: 'costs.' + missionSummaryCostHeader.key,
              className: (() => {
                const classNames = []
                if (missionSummaryCostHeader.key === 'costs.total')
                  classNames.push('table-section-end')
                return classNames.join(' ')
              })(),
              isHidden: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                isSameUserThanPreviousRow(data, cell.row.index),
              getRowSpan: (cell: Cell<MissionWithSummary>, data: MissionWithSummary[]) =>
                countRowsForUser(
                  data,
                  cell.row.original.user._id as string,
                  cell.row.original.interimAgency?._id as string,
                ),
              Cell: (data: any) => (
                <MissionSummaryCell data={data} missionSummaryHeader={missionSummaryCostHeader} />
              ),
            }
          })
          .filter(removeIfMinimalSummaryMode),
      },
      !displayDay && {
        Header: `Semaine`,
        columns: [
          {
            Header: (
              <MissionWeekIsValidatedMessage
                mission={{ weekIsValidated: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'weekIsValidated',
            Cell: (data: any) => {
              const mission: MissionWithSummaries = data.cell.row.original
              return (
                <MissionWeekIsValidatedMessage
                  mission={{ weekIsValidated: mission.weekSummary.weekIsValidated }}
                  type="icon"
                  small
                />
              )
            },
          },
          {
            Header: <MissionIsPreBilledMessage force type="icon" iconColor="dark" small />,
            accessor: 'preBilling',
            id: 'leftIsPreBilledLevel2',
            className: '',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <MissionIsPreBilledMessage
                  mission={mission}
                  type="icon"
                  small
                  week={getWeek(start)}
                  year={getWeekYear(start as Date)}
                />
              )
            },
          },
          {
            Header: 'Valider',
            accessor: 'actions.validate',
            className: 'table-section-end',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Protected
                    roles={['employerMember']}
                    action={{ resource: 'missions', name: 'validateWorkPeriod' }}
                  >
                    <MissionWeekValidateButton
                      mission={mission}
                      size="smallest"
                      onClick={() =>
                        setCurrentMissionToValidate && setCurrentMissionToValidate(mission)
                      }
                    />
                  </Protected>
                </StandardCell>
              )
            },
          },
        ],
      },
      {
        Header: `Mission`,
        columns: [
          {
            Header: (
              <MissionIsValidatedMessage
                mission={{ isValidated: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'isValidated',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <MissionIsValidatedMessage
                  mission={mission}
                  type="icon"
                  small
                  iconColor={'primary'}
                />
              )
            },
          },
          {
            Header: (
              <MissionAbandonedMessage
                mission={{ isAbandoned: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'isAbandoned',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return <MissionAbandonedMessage mission={mission} type="icon" small />
            },
          },
          {
            Header: (
              <MissionIsCancelledMessage
                mission={{ isCancelled: true }}
                type="icon"
                small
                iconColor="dark"
              />
            ),
            accessor: 'isCancelled',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return <MissionIsCancelledMessage mission={mission} type="icon" small />
            },
          },
          {
            Header: 'Interruption',
            accessor: 'actions.cancel',
            className: 'table-section-end',
            Cell: (data: any) => {
              const mission: Mission = data.cell.row.original
              return (
                <StandardCell>
                  <Protected
                    roles={['employerMember']}
                    action={{ resource: 'missions', name: 'cancel' }}
                  >
                    <MissionCancelButton
                      mission={mission}
                      size="smallest"
                      onClick={mission => {
                        setCurrentMissionToCancel && setCurrentMissionToCancel(mission)
                      }}
                    />
                  </Protected>
                </StandardCell>
              )
            },
          },
        ].filter(
          column =>
            !(
              column.accessor.match('actions') &&
              (isProtectedFor(['employerMember']) || hideActions)
            ),
        ),
      },
    ].filter(c => c) as Column[]
  }, [displayDay, start, end, summaryMinimalMode])
}

export default SummaryTable
