import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import FormWrapper from '../../components/form/form-wrapper'
import {
  Box,
  Button,
  Columns,
  Container,
  Element,
  Form,
  Heading,
  Icon,
  Level,
  Section,
} from 'react-bulma-components'
import EmployerForm from '../../components/organizations/employer-form'
import ComponentHeader from '../../components/sections/component-header'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Protected from '../../components/protected/protected'
import useStore from '../../store'
import { Cell, Column } from 'react-table'
import { EmployerAssociation } from '../../../../backend/src/services/resources/organizations/organization.model'
import Table from '../../components/table'
import RoleStatus from '../../components/users/role-status'
import { User } from '../../api/users'
import { useMutationState } from '../../queries'
import { Employer, InterimAgency } from '../../api/organizations'
import useUrlId from '../../hooks/use-url-id'
import {
  useCreateMutationKey,
  useOrganizationQuery,
  useUpdateAssocationMutatuion,
  useUpdateMutationKey,
} from '../../queries/organizations'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import Avatar from '../../components/avatar/avatar'
import PageTitle from '../../components/pages/page-title'

const EmployerDetails: React.FunctionComponent = () => {
  const { id, isNew } = useUrlId()
  const { data: organization } = useOrganizationQuery<Employer>(id, 'employer')

  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())

  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={isNew ? 'Nouvel Employeur' : organization?.name || ''}
                subTitle={
                  isNew ? 'Créer un Nouvel Employeur' : "Paramètres et Relations de l'Employeur"
                }
              />
            </Level.Side>
            <Level.Side>
              <Protected roles={['superAdmin']}>
                <Button renderAs={Link} to="/employers" outlined color="primary" size="small">
                  <Icon>
                    <ViewListIcon />
                  </Icon>
                  <span>Liste des Employeurs</span>
                </Button>
              </Protected>
            </Level.Side>
          </Level>
          <Box>
            <FormWrapper {...formWrapperProps}>
              <EmployerForm organization={organization} />
            </FormWrapper>
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerDetails

const formWrapperBaseProps = {
  loadingProps: { body: "Enregistrement de l'Employeur..." },
  successProps: {
    title: 'Employeur créé',
    body: {
      description: "L'Employeur a été créé",
      actions: [
        {
          label: 'Nouvel Employeur',
        },
        {
          label: 'Retourner à la liste',
          to: '/employers',
        },
      ],
    },
  },
}
