import React from 'react'
import Header from '../sections/header'
import '../../styles/main.scss'
import useStore from '../../store'
import useThemeSwitcher from '../../hooks/use-theme-switcher'
import { Section } from 'react-bulma-components'
import { AppNeedsUpdateBanner } from '../errors/app-needs-update'
import { Footer } from './footer'

interface LayoutProps {
  children: React.ReactNode
  hideHeader?: boolean
  hideFooter?: boolean
}

const Layout = ({ children, hideHeader, hideFooter }: LayoutProps): JSX.Element => {
  return (
    <div>
      {!hideHeader && <Header />}
      <AppNeedsUpdateBanner />
      {children}
      <Section></Section> {/* Create spacing below all pages */}
      {!hideFooter && <Footer />}
    </div>
  )
}

export default Layout
