import { LoginIcon, LogoutIcon } from '@heroicons/react/outline'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import {
  Button,
  Container,
  Dropdown,
  Element,
  Icon,
  Message,
  Navbar,
  Tag,
} from 'react-bulma-components'
import { Size } from 'react-bulma-components/src/components'
import { Link, LinkProps, useNavigate } from 'react-router-dom'
import { UserRole, UserRoleKind } from '../../../../backend/src/services/resources/users/user.model'
import { usePendingEmployerInvitationsCount } from '../../hooks/use-pending-employer-invitations-count'
import { usePendingInvitationsCount } from '../../hooks/use-pending-invitations-count'
//@ts-ignore
import * as logo from '../../images/logo.webp'
import locales from '../../../../locales'
import useStore from '../../store'
import { Organization } from '../../api/organizations'
import { SessionSlice } from '../../store/session'
import { User } from '../../api/users'
import { getRoleColorHandle } from '../../utils/colors'
import Avatar from '../avatar/avatar'
import LoginAs from '../login-as/login-as'
import Modal, { useModal } from '../modal'
import Protected from '../protected/protected'
import { AndroidMobileAppIcon, AppleMobileAppIcon, HelpIcon } from '../icons'
import { useIsDevEnvironment } from '../../hooks/use-is-dev-environment'
import config from '../../config'
import { trimTextIfLongerThan } from '../../utils/texts'
import WorkerLabel from '../texts/worker-label'
import OrganizationOptionProtected from '../protected/organization-option-protected'

interface PendingInvationsTagProps {
  count: number
  style?: React.CSSProperties
}

const Header: React.FunctionComponent = () => {
  const [displayMenu, setDisplayMenu] = useState(false)
  const currentUser = useStore(state => state.session.currentUser)
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const changeCurrentUserRole = useStore(state => state.session.changeCurrentRole)
  const logout = useStore(state => state.session.logout)
  const loggedAs = useStore(state => state.session.loggedAs)
  const pendingInvitationsCount = usePendingInvitationsCount()
  const pendingEmployerInvitationsCount = usePendingEmployerInvitationsCount()
  const navigate = useNavigate()
  const isDevEnvironment = useIsDevEnvironment()

  const modal = useModal()

  const realUserRole = loggedAs?.organization?.type ? 'superAdmin' : currentUserRole

  const changeCurrentRole = (role: UserRole) => {
    navigate('/')
    changeCurrentUserRole(role)
  }

  return (
    <Element renderAs="header">
      <Navbar
        active={displayMenu}
        fixed="top"
        data-test="header-menu"
        backgroundColor={realUserRole === 'superAdmin' ? 'super-admin-light' : 'primary-light'}
      >
        <Container breakpoint={'fluid'}>
          <Navbar.Brand>
            <Navbar.Item renderAs={Link} to="/">
              {isDevEnvironment ? (
                <Message color={'warning'}>
                  <Message.Body>DEV</Message.Body>
                </Message>
              ) : (
                <img src={logo} alt="Fandi Check In" />
              )}
            </Navbar.Item>
            <Navbar.Item renderAs="div">
              {!loggedAs ? (
                <Element renderAs="span" textColor={`primary`} textWeight="bold">
                  {currentOrganization?.name ?? realUserRole === 'worker'
                    ? trimTextIfLongerThan(currentUser?.firstName + ' ' + currentUser?.lastName, 20)
                    : locales.users.roles[realUserRole]}
                </Element>
              ) : (
                <LoggedAsStatusItem changeCurrentRole={changeCurrentRole} />
              )}
            </Navbar.Item>
            <Element
              renderAs="button"
              role="button"
              className="navbar-burger"
              onClick={() => setDisplayMenu(!displayMenu)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </Element>
          </Navbar.Brand>
          <Navbar.Menu>
            <Navbar.Container align="right">
              <Protected roles={['worker']}>
                <HeaderItem
                  href={config.mobileApps.apple.store.url}
                  data-tooltip="App mobile Apple"
                  className="has-tooltip-bottom"
                >
                  <Icon size={'small'}>
                    <AppleMobileAppIcon />
                  </Icon>
                </HeaderItem>
                <HeaderItem
                  href={config.mobileApps.android.store.url}
                  data-tooltip="App mobile Android"
                  className="has-tooltip-bottom"
                >
                  <Icon size={'small'}>
                    <AndroidMobileAppIcon />
                  </Icon>
                </HeaderItem>
              </Protected>
              <Protected roles={['superAdmin', 'worker', 'interimAgencyMember', 'employerMember']}>
                <HeaderItem bold label="Tableau de Bord" to="/" />
                <Protected
                  roles={['employerMember', 'interimAgencyMember']}
                  action={{ resource: 'missions', name: 'read' }}
                >
                  <HeaderItem bold label="Récapitulatif" to="/summary" />
                </Protected>
                <Protected
                  roles={['employerMember']}
                  action={{ resource: 'missions', name: 'read' }}
                >
                  <OrganizationOptionProtected
                    options={[{ organizationType: 'employer', option: 'biPart' }]}
                  >
                    <HeaderItem bold label="Paie" to="/payroll" />
                  </OrganizationOptionProtected>
                </Protected>
                <Protected
                  roles={['employerMember', 'interimAgencyMember']}
                  action={{ resource: 'workersRequests', name: 'read' }}
                >
                  <HeaderItem bold label="Demandes d'Intérimaires" to="/workers-requests" />
                </Protected>
                <Protected
                  roles={['employerMember', 'interimAgencyMember']}
                  action={{ resource: 'missions', name: 'read' }}
                >
                  <HeaderItem bold label="Missions" to="/missions" />
                </Protected>
                <MobileNavbarSeparator />
                <Protected roles={['employerMember', 'interimAgencyMember', 'superAdmin']}>
                  <Navbar.Item hoverable data-test="organization-submenu">
                    <Navbar.Link textWeight="bold">
                      {currentOrganization?.type === 'employer'
                        ? 'Mon Entreprise'
                        : currentOrganization?.type === 'interimAgency'
                        ? 'Mon Agence'
                        : locales.users.roles[realUserRole]}
                    </Navbar.Link>
                    <Navbar.Dropdown right>
                      <Protected roles={['employerMember', 'interimAgencyMember']}>
                        <HeaderItemTitle label="Missions" />
                        <Protected
                          roles={['employerMember', 'interimAgencyMember']}
                          action={{ resource: 'missions', name: 'read' }}
                        >
                          <HeaderItem label="Missions" to="/missions" />
                        </Protected>
                        <Protected
                          roles={['employerMember']}
                          action={{ resource: 'clockings', name: 'read' }}
                        >
                          <HeaderItem label="Pointage" to="/clockings" />
                        </Protected>
                        <Protected
                          roles={['employerMember']}
                          action={{ resource: 'missions', name: 'read' }}
                        >
                          <HeaderItem label="Journées de travail" to="/work-periods" />
                        </Protected>
                        <Protected
                          roles={['employerMember', 'interimAgencyMember']}
                          action={{ resource: 'preBillings', name: 'read' }}
                        >
                          <HeaderItem label="Pré-Facturation" to="/pre-billings" />
                        </Protected>
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                      </Protected>
                      <Protected roles={['superAdmin']}>
                        <HeaderItemTitle label="Organisations" />
                        <HeaderItem label="Agences d'Intérim" to="/agencies" />
                        <HeaderItem label="Employeurs" to="/employers" />
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                        <Navbar.Item onClick={() => modal.setIsDisplayed(!modal.isDisplayed)}>
                          <Icon.Text flexWrap="nowrap">
                            <Icon color="grey">
                              <LoginIcon />
                            </Icon>
                            <Element renderAs="span">Me connecter en tant que...</Element>
                          </Icon.Text>
                        </Navbar.Item>
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                      </Protected>
                      <Protected
                        roles={['superAdmin', 'interimAgencyMember', 'employerMember']}
                        atLeastOneAction={[{ resource: 'users', name: 'create' }]}
                      >
                        <HeaderItemTitle label="Utilisateurs" />
                        <Protected roles={['superAdmin']}>
                          <HeaderItem label="Super Admins" to="/super-admins" />
                        </Protected>
                        <Protected roles={['interimAgencyMember']}>
                          <HeaderItem label={<WorkerLabel plural />} to="/workers" />
                          <HeaderItem label="Membres de mon Agence" to="/interim-agency-members" />
                        </Protected>
                        <Protected
                          roles={['employerMember']}
                          action={{ resource: 'users', name: 'create' }}
                        >
                          <OrganizationOptionProtected
                            options={[{ organizationType: 'employer', option: 'biPart' }]}
                          >
                            <HeaderItem label={<WorkerLabel plural />} to="/workers" />
                          </OrganizationOptionProtected>
                          <HeaderItem label="Employés" to="/employer-members" />
                        </Protected>
                      </Protected>
                      <Protected roles={['interimAgencyMember']}>
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                        <HeaderItemTitle label="UE" />
                        <HeaderItem label="Entreprises Utilisatrices" to="/associations" />
                      </Protected>
                      <Protected
                        roles={['interimAgencyMember', 'employerMember']}
                        atLeastOneAction={[
                          { resource: 'workPeriodEvents', name: 'create' },
                          { resource: 'clockingRules', name: 'create' },
                          { resource: 'jobTitles', name: 'create' },
                          { resource: 'organizations', name: 'update' },
                        ]}
                      >
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                        <HeaderItemTitle label="Paramètres" />
                        <Protected roles={['interimAgencyMember']}>
                          <HeaderItem
                            label="Mon Agence"
                            to={`/agencies/${currentOrganization?._id}`}
                          />
                        </Protected>
                        <Protected roles={['employerMember']}>
                          <Protected
                            roles={['employerMember']}
                            action={{ resource: 'clockingRules', name: 'create' }}
                          >
                            <HeaderItem label="Agences partenaires" to={`/agencies`}>
                              <PendingInvationsTag
                                count={pendingEmployerInvitationsCount}
                                style={{ position: 'absolute', top: 5, right: 18 }}
                              />
                            </HeaderItem>
                            <HeaderItem label="Règles de Pointage" to="/clocking-rules" />
                          </Protected>

                          <Protected
                            roles={['employerMember']}
                            action={{ resource: 'workPeriodEvents', name: 'create' }}
                          >
                            <HeaderItem label="Évènements" to="/work-period-events" />
                          </Protected>
                          <Protected
                            roles={['employerMember']}
                            action={{ resource: 'jobTitles', name: 'create' }}
                          >
                            <HeaderItem label="Intitulés de Poste" to="/job-titles" />
                          </Protected>
                          <Protected
                            roles={['employerMember']}
                            action={{ resource: 'organizations', name: 'update' }}
                          >
                            <HeaderItem
                              label="Ma Société"
                              to={`/employers/${currentOrganization?._id}`}
                            />
                          </Protected>
                        </Protected>
                      </Protected>
                    </Navbar.Dropdown>
                    <PendingInvationsTag
                      count={pendingEmployerInvitationsCount}
                      style={{ position: 'absolute', top: 2, right: 30 }}
                    />
                  </Navbar.Item>
                </Protected>
                {!loggedAs ? (
                  <>
                    <MobileNavbarSeparator />
                    <Navbar.Item hoverable data-test="user-submenu">
                      <Navbar.Link textWeight="bold">
                        {currentUser && (
                          <>
                            <Element className="is-hidden-touch">
                              <Avatar
                                firstName={currentUser.firstName}
                                lastName={currentUser.lastName}
                                small={true}
                              />
                            </Element>
                            <Element className="is-hidden-desktop">
                              <Avatar
                                firstName={currentUser.firstName}
                                lastName={currentUser.lastName}
                                small={false}
                              />
                            </Element>
                          </>
                        )}
                        <PendingInvationsTag
                          count={pendingInvitationsCount}
                          style={{ position: 'absolute', top: 2, right: 30 }}
                        />
                      </Navbar.Link>
                      <Navbar.Dropdown right>
                        <UserRoles
                          user={currentUser}
                          currentRole={currentUserRole}
                          currentOrganization={currentOrganization}
                          changeCurrentRole={changeCurrentRole}
                        />
                        <HeaderItemTitle label="Mes Relations" />
                        <HeaderItem label="Mes Relations" to="/me/organizations">
                          <Element renderAs="span" ml={1}>
                            <PendingInvationsTag count={pendingInvitationsCount} />
                          </Element>
                        </HeaderItem>
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                        <HeaderItemTitle label="Paramètres" />
                        <HeaderItem label="Mes Informations" to="/me" />
                        <HeaderItem label="Notifications" to="/me/notifications" />
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                        <HeaderItem to="/help" label="Aide" icon={HelpIcon} />
                        <Dropdown.Divider touch={{ display: 'hidden' }} />
                        <Navbar.Item data-test="user-logout" onClick={() => logout()}>
                          <Icon.Text>
                            <Icon>
                              <LogoutIcon />
                            </Icon>
                            <Element renderAs="span">Déconnexion</Element>
                          </Icon.Text>
                        </Navbar.Item>
                      </Navbar.Dropdown>
                    </Navbar.Item>
                  </>
                ) : (
                  <Navbar.Item touch={{ display: 'hidden' }}>
                    <LoggedAsStatusItem changeCurrentRole={changeCurrentRole} size="smallest" />
                  </Navbar.Item>
                )}
              </Protected>
              <Protected roles={['guest']}>
                <HeaderItem bold label="Connexion" to="/login" />
              </Protected>
            </Navbar.Container>
          </Navbar.Menu>
        </Container>
      </Navbar>
      <Protected roles={['superAdmin']}>
        <Modal title="Me connecter en tant que..." actions={modal}>
          <LoginAs onSelect={() => modal.setIsDisplayed(false)} />
        </Modal>
      </Protected>
    </Element>
  )
}

interface LoggedAsStatusItemProps {
  size?: Size | 'smallest'
  changeCurrentRole(role: UserRole): void
}

const LoggedAsStatusItem: React.FC<LoggedAsStatusItemProps> = ({
  size = 'normal',
  changeCurrentRole,
}) => {
  const currentUser = useStore(state => state.session.currentUser)
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const loggedAs = useStore(state => state.session.loggedAs)
  const loggedAsColorHandle = loggedAs?.organization?.type && getRoleColorHandle(currentUserRole)

  if (loggedAs?.organization?.name) {
    return (
      <Button
        color={loggedAsColorHandle}
        size={size === 'smallest' ? 'small' : size}
        onClick={() => {
          const superAdminRole = currentUser?.roles.find(role => role.kind === 'superAdmin')
          superAdminRole && changeCurrentRole(superAdminRole)
        }}
      >
        {size !== 'smallest' && (
          <Element renderAs="span" textWeight="bold" textColor={`${loggedAsColorHandle}-light`}>
            {loggedAs?.organization?.name}
          </Element>
        )}
        <Icon textColor={`${loggedAsColorHandle}-light`}>
          <XIcon />
        </Icon>
      </Button>
    )
  }
  return <></>
}

const PendingInvationsTag: React.FunctionComponent<PendingInvationsTagProps> = ({
  count,
  style,
}) => {
  if (count === 0) return <></>
  return (
    <Tag
      color={'warning'}
      style={style} //TODO: Tom ... : )
      title={`${count} invitation(s) en attente`}
      data-test={'pending-invitations-notification'}
    >
      {count}
    </Tag>
  )
}

const HeaderItemTitle: React.FunctionComponent<{ label: string }> = ({ label }) => (
  <Navbar.Item textWeight="bold" renderAs="div">
    {label}
  </Navbar.Item>
)

interface HeaderItemBaseProps {
  label?: React.ReactNode
  bold?: boolean
  icon?: React.FunctionComponent
  [key: string]: any
}

type HeaderItemRouterLink = HeaderItemBaseProps & {
  to: LinkProps['to']
}
type HeaderItemWebLink = HeaderItemBaseProps & {
  href: HTMLAnchorElement['href']
}

const HeaderItem: React.FunctionComponent<HeaderItemRouterLink | HeaderItemWebLink> = ({
  label,
  bold = false,
  to,
  href,
  icon,
  children,
  ...rest
}) => {
  const CustomIcon = icon
  const RenderAs = to ? Link : 'a'
  return (
    <Navbar.Item
      renderAs={RenderAs}
      {...(to ? { to } : undefined)}
      {...(href ? { href } : undefined)}
      {...(bold ? { textWeight: 'bold' } : {})}
      {...rest}
    >
      {CustomIcon ? (
        <Icon.Text>
          <Icon>
            <CustomIcon />
          </Icon>
          <Element renderAs="span">Aide</Element>
        </Icon.Text>
      ) : (
        label
      )}
      {children}
    </Navbar.Item>
  )
}

interface UserRolesProps {
  user?: User
  currentRole: UserRoleKind | 'guest'
  currentOrganization?: SessionSlice['currentOrganization']
  changeCurrentRole: SessionSlice['changeCurrentRole']
}

const UserRoles: React.FunctionComponent<UserRolesProps> = ({
  user,
  currentRole,
  changeCurrentRole,
  currentOrganization,
}) => {
  const { commonRoles, organizationsRoles } = React.useMemo(() => {
    if (!user) return { commonRoles: [], organizationsRoles: [] }
    return {
      commonRoles: user.roles.filter(
        role =>
          (role.kind === 'worker' || role.kind === 'superAdmin') && role.status === 'accepted',
      ),
      organizationsRoles: user.roles.filter(
        role =>
          (role.kind === 'interimAgencyMember' || role.kind === 'employerMember') &&
          role.status === 'accepted',
      ),
    }
  }, [user, currentRole])

  if (commonRoles.length + organizationsRoles.length <= 1) return <></>

  return (
    <>
      {(commonRoles[0] || organizationsRoles[0]) &&
        commonRoles.length + organizationsRoles.length > 1 && (
          <>
            <HeaderItemTitle label="Changer de profil" />
            {commonRoles.map(role => (
              <Navbar.Item onClick={() => changeCurrentRole(role)} key={role._id as string}>
                <Icon.Text flexWrap="nowrap">
                  <Icon
                    mr={1}
                    color={`${getRoleColorHandle(role.kind)}-dark`}
                    backgroundColor={`${getRoleColorHandle(role.kind)}-light`}
                  >
                    {currentRole === role.kind ? <CheckIcon /> : ''}
                  </Icon>
                  {locales.users.roles[role.kind]}
                </Icon.Text>
              </Navbar.Item>
            ))}
            {organizationsRoles.map(role => {
              const organization = role.organization as Organization
              return (
                <Navbar.Item onClick={() => changeCurrentRole(role)} key={role._id as string}>
                  <Icon.Text flexWrap="nowrap">
                    <Icon
                      mr={1}
                      color={`${getRoleColorHandle(organization.type)}-dark`}
                      backgroundColor={`${getRoleColorHandle(organization.type)}-light`}
                    >
                      {currentOrganization && currentOrganization._id === organization._id ? (
                        <CheckIcon />
                      ) : (
                        ''
                      )}
                    </Icon>
                    {organization.name}
                  </Icon.Text>
                </Navbar.Item>
              )
            })}
          </>
        )}
      <Dropdown.Divider touch={{ display: 'hidden' }} />
    </>
  )
}

export default Header

const MobileNavbarSeparator = () => {
  return <Element renderAs="hr" my={1} desktop={{ display: 'hidden' }} />
}
