import React from 'react'
import { useRoutes as routerUseRoutes } from 'react-router-dom'
import ClockingsList from '../pages/clockings/list'
import Login from '../pages/login/login'
import MissionDetails from '../pages/missions/details'
import MissionsList from '../pages/missions/list'
import AgenciesList from '../pages/organizations/agencies-list'
import AgencyDetails from '../pages/organizations/agency-details'
import QrScanner from '../pages/qr-scanner/qr-scanner'
import Summary from '../pages/summary'
import UserDetails from '../pages/users/details'
import UsersList from '../pages/users/list'
import UserOrganizations from '../pages/users/user-organizations'
import WorkPeriodsList from '../pages/work-periods/list'
import UserCompleteProfile from '../pages/users/complete-profile'
import UserRequestNewPassword from '../pages/users/request-new-password'
import UserResetPassword from '../pages/users/reset-password'
import UserValidateNewEmail from '../pages/users/validate-new-email'
import WorkPeriodDetails from '../pages/work-periods/details'
import Authenticated from '../containers/authenticated'
import Guest from '../containers/guest'
import WorkerDashboard from '../pages/dashboards/worker-dashboard'
import EmployersList from '../pages/organizations/employers-list'
import EmployerDetails from '../pages/organizations/employer-details'
import AssociationsList from '../pages/associations/list'
import QrPrintPage from '../pages/qr-print/qr-print'
import JobTitlesList from '../pages/job-titles/list'
import JobTitleDetails from '../pages/job-titles/details'
import ClockingsRulesList from '../pages/clocking-rules/list'
import ClockingRuleDetails from '../pages/clocking-rules/details'
import Protected from '../components/protected/protected'
import SuperAdminDashboard from '../pages/dashboards/super-admin-dashboard'
import EmployerDashboard from '../pages/dashboards/employer-dashboard'
import InterimAgencyDashboard from '../pages/dashboards/interim-agency-dashboard'
import WorkersRequestsList from '../pages/workers-requests/list'
import WorkersRequestsDetails from '../pages/workers-requests/details'
import WorkersRequestsEdition from '../pages/workers-requests/edition'
import MissionEdition from '../pages/missions/edition'
import { HelpPage } from '../pages/common/help'
import PreBillingsList from '../pages/pre-billings/list'
import PreBillingDetails from '../pages/pre-billings/details'
import { PrivacyPolicyPage } from '../pages/common/privacy-policy'
import { TermsOfUsePage } from '../pages/common/terms-of-use'
import UserNotificationsSettings from '../pages/users/notifications-settings'
import NoActiveRole from '../pages/errors/no-active-role'
import WorkPeriodEventsList from '../pages/work-periods-events/list'
import PayrollDetails from '../pages/payroll/details'
import EmployerDetailsForInterimAgency from '../pages/organizations/employer-details-for-interim-agency'
import AgencyDetailsForEmployer from '../pages/organizations/agency-details-for-employer'
import AgenciesListForEmployer from '../pages/organizations/agencies-list-for-employer'

const useRoute = (): React.ReactElement | null => {
  return routerUseRoutes([
    //Guest
    {
      path: '/complete-profile',
      element: (
        <Guest>
          <UserCompleteProfile />
        </Guest>
      ),
    },
    {
      path: '/request-new-password',
      element: (
        <Guest>
          <UserRequestNewPassword />
        </Guest>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <Guest>
          <UserResetPassword />
        </Guest>
      ),
    },
    {
      path: '/login',
      element: (
        <Guest>
          <Login />
        </Guest>
      ),
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: '/terms-of-use',
      element: <TermsOfUsePage />,
    },
    {
      path: '/qr-scanner/:name',
      element: <QrScanner />,
    },
    //Authenticated
    {
      path: '/qr-scanner',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'clockings', name: 'create' }}
        >
          <QrScanner />
        </Authenticated>
      ),
    },
    {
      path: '/errors/no-active-role',
      element: <NoActiveRole />,
    },
    {
      path: '/help',
      element: (
        <Authenticated>
          <HelpPage />
        </Authenticated>
      ),
    },
    {
      path: '/missions/new',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'missions', name: 'create' }}
          organizationOptions={[{ organizationType: 'employer', option: 'biPart' }]}
        >
          <MissionEdition />
        </Authenticated>
      ),
    },
    {
      path: '/missions/:id/edit',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'missions', name: 'update' }}>
          <MissionEdition />
        </Authenticated>
      ),
    },
    {
      path: '/missions/:id',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'missions', name: 'read' }}
        >
          <MissionDetails />
        </Authenticated>
      ),
    },
    {
      path: '/missions',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'missions', name: 'read' }}
        >
          <MissionsList />
        </Authenticated>
      ),
    },
    {
      path: '/missions/:missionId/work-periods/:workPeriodId',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'missions', name: 'read' }}>
          <WorkPeriodDetails />
        </Authenticated>
      ),
    },
    {
      path: '/work-periods',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'missions', name: 'read' }}>
          <WorkPeriodsList />
        </Authenticated>
      ),
    },
    {
      path: '/agencies',
      element: (
        <Authenticated roles={['superAdmin', 'employerMember']}>
          <Protected roles={['superAdmin']}>
            <AgenciesList />
          </Protected>
          <Protected
            roles={['employerMember']}
            action={{ resource: 'organizations', name: 'update' }}
          >
            <AgenciesListForEmployer />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/agencies/:id',
      element: (
        <Authenticated roles={['superAdmin', 'interimAgencyMember', 'employerMember']}>
          <Protected roles={['superAdmin', 'interimAgencyMember']}>
            <AgencyDetails />
          </Protected>

          <Protected
            roles={['employerMember']}
            action={{ resource: 'organizations', name: 'update' }}
          >
            <AgencyDetailsForEmployer />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/employers',
      element: (
        <Authenticated roles={['superAdmin']}>
          <EmployersList />
        </Authenticated>
      ),
    },
    {
      path: '/employers/:id',
      element: (
        <Authenticated
          roles={['superAdmin', 'employerMember', 'interimAgencyMember']}
          action={{ resource: 'organizations', name: 'update' }}
        >
          <Protected
            roles={['superAdmin', 'employerMember']}
            action={{ resource: 'organizations', name: 'update' }}
          >
            <EmployerDetails />
          </Protected>
          <Protected roles={['interimAgencyMember']}>
            <EmployerDetailsForInterimAgency />
          </Protected>
        </Authenticated>
      ),
    },
    {
      path: '/clockings',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'clockings', name: 'read' }}>
          <ClockingsList />
        </Authenticated>
      ),
    },
    {
      path: '/summary',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'missions', name: 'read' }}
        >
          <Summary />
        </Authenticated>
      ),
    },
    {
      path: '/payroll',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'missions', name: 'read' }}
          organizationOptions={[{ organizationType: 'employer', option: 'biPart' }]}
        >
          <PayrollDetails />
        </Authenticated>
      ),
    },
    {
      path: '/workers',
      element: (
        <Authenticated
          roles={['interimAgencyMember', 'employerMember']}
          action={{ resource: 'users', name: 'update' }}
          organizationOptions={[{ organizationType: 'employer', option: 'biPart' }]}
        >
          <UsersList />
        </Authenticated>
      ),
    },
    {
      path: '/super-admins',
      element: (
        <Authenticated roles={['superAdmin']}>
          <UsersList />
        </Authenticated>
      ),
    },
    {
      path: '/interim-agency-members',
      element: (
        <Authenticated roles={['interimAgencyMember']}>
          <UsersList />
        </Authenticated>
      ),
    },
    {
      path: '/employer-members',
      element: (
        <Authenticated roles={['employerMember']} action={{ resource: 'users', name: 'update' }}>
          <UsersList />
        </Authenticated>
      ),
    },
    {
      path: '/me/organizations',
      element: (
        <Authenticated>
          <UserOrganizations />
        </Authenticated>
      ),
    },
    {
      path: '/me',
      element: (
        <Authenticated>
          <UserDetails />
        </Authenticated>
      ),
    },
    {
      path: '/me/notifications',
      element: (
        <Authenticated>
          <UserNotificationsSettings />
        </Authenticated>
      ),
    },
    {
      path: '/print-qr/:userId',
      element: (
        <Authenticated>
          <QrPrintPage />
        </Authenticated>
      ),
    },
    {
      path: '/associations',
      element: (
        <Authenticated roles={['interimAgencyMember']}>
          <AssociationsList />
        </Authenticated>
      ),
    },
    {
      path: '/clocking-rules',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'clockingRules', name: 'update' }}
        >
          <ClockingsRulesList />
        </Authenticated>
      ),
    },
    {
      path: '/clocking-rules/:clockingRulesId',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'clockingRules', name: 'update' }}
        >
          <ClockingRuleDetails />
        </Authenticated>
      ),
    },
    {
      path: '/job-titles',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'jobTitles', name: 'update' }}
        >
          <JobTitlesList />
        </Authenticated>
      ),
    },
    {
      path: '/job-titles/:id',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'jobTitles', name: 'update' }}
        >
          <JobTitleDetails />
        </Authenticated>
      ),
    },
    {
      path: '/work-period-events',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'workPeriodEvents', name: 'update' }}
        >
          <WorkPeriodEventsList />
        </Authenticated>
      ),
    },
    /*{
      path: '/work-period-events/:id',
      element: (
        <Authenticated roles={['employerMember']}>
          <WorkPeriodEventDetails />
        </Authenticated>
      ),
    },*/
    {
      path: '/pre-billings',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'preBillings', name: 'read' }}
        >
          <PreBillingsList />
        </Authenticated>
      ),
    },
    {
      path: '/pre-billings/:id',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'preBillings', name: 'read' }}
        >
          <PreBillingDetails />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'workersRequests', name: 'read' }}
        >
          <WorkersRequestsList />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/new',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'workersRequests', name: 'create' }}
        >
          <WorkersRequestsEdition />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/:id/edit',
      element: (
        <Authenticated
          roles={['employerMember']}
          action={{ resource: 'workersRequests', name: 'update' }}
        >
          <WorkersRequestsEdition />
        </Authenticated>
      ),
    },
    {
      path: '/workers-requests/:id',
      element: (
        <Authenticated
          roles={['employerMember', 'interimAgencyMember']}
          action={{ resource: 'workersRequests', name: 'read' }}
        >
          <WorkersRequestsDetails />
        </Authenticated>
      ),
    },
    {
      path: '/',
      element: (
        <Authenticated>
          <Protected roles={['worker']}>
            <WorkerDashboard />
          </Protected>
          <Protected roles={['interimAgencyMember']}>
            <InterimAgencyDashboard />
          </Protected>
          <Protected roles={['superAdmin']}>
            <SuperAdminDashboard />
          </Protected>
          <Protected roles={['employerMember']}>
            <EmployerDashboard />
          </Protected>
        </Authenticated>
      ),
    },
    //Common
    { path: '/validate-new-email', element: <UserValidateNewEmail /> },
  ])
}

export default useRoute
