import React from 'react'
import Layout from '../../components/layout/layout'
import {
  Box,
  Button,
  Columns,
  Container,
  Heading,
  Icon,
  Level,
  Section,
} from 'react-bulma-components'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import useStore from '../../store'
import { Employer, Organization } from '../../api/organizations'
import useUrlId from '../../hooks/use-url-id'
import { useOrganizationQuery } from '../../queries/organizations'
import PageTitle from '../../components/pages/page-title'
import { useJobTitlesQuery } from '../../queries/job-titles'
import DescriptionList from '../../components/description-list/description-list'
import { getJobTitleLaborCoefficientByInterimAgency } from '../../utils/job-titles'
import { JobTitle } from '../../api/job-titles'
import { sortByIgnoreCase } from '../../utils/arrays'

const EmployerDetailsForInterimAgency: React.FunctionComponent = () => {
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const { id } = useUrlId()
  const { data: employer } = useOrganizationQuery<Employer>(id, 'employer')
  const jobTitlesQuery = useJobTitlesQuery({ employer: id })
  const jobTitles = sortByIgnoreCase<JobTitle>(jobTitlesQuery.data || [], 'name')

  if (!employer || !jobTitles || !currentOrganization) return <></>

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle title={employer.name} subTitle={`Vous collaborez avec ${employer.name}`} />
            </Level.Side>
            <Level.Side>
              <Button renderAs={Link} to="/associations" outlined color="primary" size="small">
                <Icon>
                  <ViewListIcon />
                </Icon>
                <span>Liste des Entreprises Utilisatrices partenaires</span>
              </Button>
            </Level.Side>
          </Level>
          <Heading size={4}>Intitulés de Poste & Coefficients main d'oeuvre</Heading>
          <Box>
            <Columns>
              <Columns.Column>
                <LaborCostCoefficientsList
                  jobTitles={jobTitles}
                  currentOrganization={currentOrganization}
                  filter={(j, i) => i % 2 === 0}
                />
              </Columns.Column>
              <Columns.Column>
                <LaborCostCoefficientsList
                  jobTitles={jobTitles}
                  currentOrganization={currentOrganization}
                  filter={(j, i) => i % 2 !== 0}
                />
              </Columns.Column>
            </Columns>
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerDetailsForInterimAgency

const LaborCostCoefficientsList: React.FC<{
  jobTitles: JobTitle[]
  currentOrganization: Organization
  filter(jobTitle: JobTitle, index: number): boolean
}> = ({ currentOrganization, jobTitles, filter }) => (
  <DescriptionList
    labelColumnWidth={300}
    items={jobTitles.filter(filter).map(jobTitle => ({
      label: jobTitle.name,
      value:
        getJobTitleLaborCoefficientByInterimAgency(jobTitle, currentOrganization._id)?.value ||
        'N/A',
    }))}
  />
)
