import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputField from '../form/fields/input'
import { useUpdateMutation } from '../../queries/job-titles'
import { JobTitle } from '../../api/job-titles'
import { Element } from 'react-bulma-components'
import { RequestButton } from '../request-components/request-components'
import { InterimAgency } from '../../api/organizations'
import { compareDocsById } from '../../utils/documents'
import { getJobTitleLaborCoefficientByInterimAgency } from '../../utils/job-titles'

interface JobTitleLaborCoefficientFormProps {
  jobTitle: JobTitle
  interimAgency: InterimAgency
}

const JobTitleLaborCoefficientForm: React.FC<JobTitleLaborCoefficientFormProps> = ({
  jobTitle,
  interimAgency,
}) => {
  const updateMutation = useUpdateMutation()
  const laborCostCoefficient = getJobTitleLaborCoefficientByInterimAgency(jobTitle, interimAgency)

  return (
    <Formik
      initialValues={{ laborCostCoefficientValue: laborCostCoefficient?.value }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.laborCostCoefficientValue) errors.laborCostCoefficientValue = 'Champ requis'
        return errors
      }}
      onSubmit={values => {
        const laborCostCoefficients = jobTitle.laborCostCoefficients.map(l => {
          if (compareDocsById(l.interimAgency, interimAgency))
            l.value = values.laborCostCoefficientValue as number
          return l
        })
        updateMutation.mutate({
          id: jobTitle._id,
          laborCostCoefficients,
        })
      }}
    >
      {({ values }) => {
        return (
          <Form autoComplete="off">
            <Element>
              <Field
                label={jobTitle.name}
                name={`laborCostCoefficientValue`}
                component={InputField}
                type="number"
                required
                step="0.1"
              />
            </Element>
            {values.laborCostCoefficientValue !== laborCostCoefficient?.value && (
              <Element my={2}>
                <RequestButton color="primary" type="submit" mutation={updateMutation}>
                  Mettre à jour
                </RequestButton>
              </Element>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
export default JobTitleLaborCoefficientForm
