import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import { Button, Container, Section } from 'react-bulma-components'
import useStore from '../../store'
import { Employer, InterimAgency } from '../../api/organizations'
import { Cell, Column } from 'react-table'
import { useOrganizationQuery, useUpdateAssocationMutatuion } from '../../queries/organizations'
import { EmployerAssociation } from '../../../../backend/src/services/resources/organizations/organization.model'
import { Link } from 'react-router-dom'
import RoleStatus from '../../components/users/role-status'
import Avatar from '../../components/avatar/avatar'
import { User } from '../../api/users'
import Protected from '../../components/protected/protected'
import Table from '../../components/table'

const AgenciesListForEmployer: React.FC = ({}) => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as Employer

  const { data: organization } = useOrganizationQuery<Employer>(
    currentOrganization._id!,
    'employer',
  )

  const associationsColumns = useAssociationsListTable(
    currentOrganization?.associations || [],
    currentOrganization,
  )

  return (
    <Layout>
      <PageHeading title="Liste des Agences d'Intérim partenaires" />
      <Section>
        <Container>
          <Table columns={associationsColumns} data={organization?.associations || []} />
        </Container>
      </Section>
    </Layout>
  )
}

export default AgenciesListForEmployer

const useAssociationsListTable = (
  associations: Employer['associations'],
  organization: Employer,
): Column[] => {
  const updateAssociationMutation = useUpdateAssocationMutatuion()
  return React.useMemo(
    () =>
      [
        {
          Header: "Agence d'intérim",
          accessor: 'interimAgncy',
          Cell: (cell: Cell<EmployerAssociation>) => {
            const association = cell.row.original
            const interimAgency = association.interimAgency as InterimAgency
            if (association.status === 'accepted')
              return <Link to={`/agencies/${interimAgency._id}`}>{interimAgency.name}</Link>
            else return interimAgency.name
          },
        },
        {
          Header: "Statut de l'invitation",
          accessor: 'status',
          Cell: (data: any) => {
            return <RoleStatus role={data.cell.row.original} showDate />
          },
        },
        {
          Header: 'Invité par',
          accessor: 'invitedBy',
          Cell: (cell: Cell<EmployerAssociation>) => {
            const user = cell.row.original.invitedBy as User
            return user ? <Avatar {...user} /> : ''
          },
        },
        {
          Header: 'Action',
          accessor: '__actions',
          Cell: (data: any) => {
            const association: EmployerAssociation = data.cell.row.original
            if (association.status === 'pending')
              return (
                <Protected roles={['employerMember']}>
                  <Button
                    size={'small'}
                    color="success"
                    mr={1}
                    onClick={() =>
                      updateAssociationMutation.mutate({
                        employerId: organization._id.toString(),
                        associationId: association._id as string,
                        status: 'accepted',
                      })
                    }
                  >
                    Accepter
                  </Button>
                </Protected>
              )
            return ''
          },
        },
      ] as Column[],
    [associations, organization],
  )
}
