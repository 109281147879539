import { QueryKey, useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import { UserRole } from '../../../backend/src/services/resources/users/user.model'
import { CreateUserParams } from '../../../backend/src/services/resources/users/users.controller.service.types'
import useStore from '../store'
import { Organization } from '../api/organizations'
import {
  AvailableWorker,
  deleteAccount,
  discontinueUserOrganizationRole,
  discontinueWorkerAssociation,
  FetchAvailableParams,
  fetchAvailableWorkers,
  fetchWorkers,
  FetchWorkersParams,
  get,
  regenerateQrCode,
  update,
  updateAssociation,
  updateNotificationSettings,
  UpdateNotificationSettingsPayload,
  updateRole,
  UpdateUserAssociationPayload,
  updateUserEmail,
  updateUserOrganizationRole,
  UpdateUserOrganizationRolePayload,
  UpdateUserPayload,
  UpdateUserRolePayload,
  updateWorker,
  UpdateWorkerPayload,
} from '../api/users'
import {
  auth,
  AuthPayload,
  AuthResponse,
  completeProfile,
  CompleteUserProfilePayload,
  create,
  fetch,
  FetchUsersParams,
  getUserQrCode,
  isAuth,
  requestNewPassword,
  RequestNewPasswordPayload,
  resetPassword,
  ResetUserPasswordPayload,
  User,
  validateNewEmail,
} from '../api/users'
import axios, { AxiosError } from 'axios'

//Queries
export const useUsersQuery = (
  filters?: FetchUsersParams,
  options?: QueryOptions<any>,
): UseQueryResult<User[]> =>
  useResources<User[], FetchUsersParams>('users', fetch, filters || {}, options)

export const useUserQuery = (id?: string): UseQueryResult<User> => useResource('users', get, id)

export const useUserQrQuery = (userId?: string): UseQueryResult<string> =>
  useQuery(['user', userId, 'qr'], async ({ queryKey }) => getUserQrCode(queryKey[1] as string), {
    enabled: Boolean(userId),
  })

export const useAvailableWorkersQuery = (
  params: FetchAvailableParams,
  options?: QueryOptions,
): UseQueryResult<AvailableWorker[]> =>
  useResources<AvailableWorker[], FetchAvailableParams>(
    'workers',
    fetchAvailableWorkers,
    params,
    options,
  )

export const useWorkersQuery = (
  filters?: FetchWorkersParams,
  options?: QueryOptions<any>,
): UseQueryResult<User[]> =>
  useResources<User[], FetchWorkersParams>('workers', fetchWorkers, filters || {}, options)

//Mutations
const onMutationSuccess = () => {
  queryClient.invalidateQueries(['users'])
  queryClient.invalidateQueries(useIsAuthQueryKey())
  queryClient.invalidateQueries(['app-config'])
}

export const useCreateMutationKey = (): QueryKey => ['users', 'create']
export const useCreateMutation = (): UseMutationResult<User, CreateUserParams, any> =>
  useMutation(create, {
    mutationKey: useCreateMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdateMutationKey = (): QueryKey => ['users', 'update']
export const useUpdateMutation = (): UseMutationResult<User, UpdateUserPayload, any> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdatWorkerMutationKey = (): QueryKey => ['users', 'workers', 'update']
export const useUpdateWorkerMutation = (): UseMutationResult<User, UpdateWorkerPayload, any> =>
  useMutation(updateWorker, {
    mutationKey: useUpdatWorkerMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdateEmailMutationKey = (): QueryKey => ['users', 'update', 'email']
export const useUpdateEmailMutation = (): UseMutationResult<User, string, any> =>
  useMutation(updateUserEmail, { mutationKey: useUpdateEmailMutationKey() })

const useUpdateNotificationSettingsKey = (): QueryKey => [
  'users',
  'update',
  'notificaiton-settings',
]
export const useUpdateNotificationSettingsMutation = (): UseMutationResult<
  User,
  UpdateNotificationSettingsPayload,
  any
> =>
  useMutation(updateNotificationSettings, {
    mutationKey: useUpdateNotificationSettingsKey(),
    onSuccess: onMutationSuccess,
  })

const useCompleteProfileKey = (): QueryKey => ['users', 'complete-profile']
export const useCompleteProfile = (): UseMutationResult<User, CompleteUserProfilePayload, any> =>
  useMutation(completeProfile, { mutationKey: useCompleteProfileKey() })

export const useRequestPasswordMutationKey = (): QueryKey => ['users', 'request-password']
export const useRequestPasswordMutation = (): UseMutationResult<
  User,
  RequestNewPasswordPayload,
  any
> => useMutation(requestNewPassword, { mutationKey: useRequestPasswordMutationKey() })

export const useResetPasswordMutationKey = (): QueryKey => ['users', 'reset-password']
export const useResetPasswordMutation = (): UseMutationResult<
  User,
  ResetUserPasswordPayload,
  any
> => useMutation(resetPassword, { mutationKey: useResetPasswordMutationKey() })

const useDeleteAccountMutationkey = (): QueryKey => ['users', 'delete']
export const useDeleteAccountMutation = (): UseMutationResult<User, User['_id'], any> => {
  return useMutation(deleteAccount, { mutationKey: useDeleteAccountMutationkey() })
}

const useValidateNewEmailMutationKey = (): QueryKey => ['users', 'validate-new-email']
export const useValidateNewEmailMutation = (): UseMutationResult<User, string, any> =>
  useMutation(validateNewEmail, {
    mutationKey: useValidateNewEmailMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdateUserRoleMutationKey = (): QueryKey => ['users', 'roles', 'update']
export const useUpdateUserRoleMutation = (): UseMutationResult<User, UpdateUserRolePayload, any> =>
  useMutation(updateRole, {
    mutationKey: useUpdateUserRoleMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdateUserAssociationMutationKey = (): QueryKey => ['users', 'associations', 'update']
export const useUpdateUserAssociationMutation = (): UseMutationResult<
  User,
  UpdateUserAssociationPayload,
  any
> =>
  useMutation(updateAssociation, {
    mutationKey: useUpdateUserAssociationMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useDiscontinueUserOrganizationRoleMutationKey = (): QueryKey => ['users', 'roles', 'delete']
export const useDiscontinueUserOrganizationRoleMutation = (): UseMutationResult<
  User,
  { userId: string },
  any
> =>
  useMutation(discontinueUserOrganizationRole, {
    mutationKey: useDiscontinueUserOrganizationRoleMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useUpdateUserOrganizationRoleMutationKey = (): QueryKey => ['users', 'roles', 'update']
export const useUpdateUserOrganizationRoleMutation = (): UseMutationResult<
  User,
  any,
  UpdateUserOrganizationRolePayload
> =>
  useMutation(updateUserOrganizationRole, {
    mutationKey: useUpdateUserOrganizationRoleMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useDiscontinueWorkerAssociationMutationKey = (): QueryKey => [
  'users',
  'associations',
  'delete',
]
export const useDiscontinueWorkerAssociationMutation = (): UseMutationResult<
  User,
  { workerId: string },
  any
> =>
  useMutation(discontinueWorkerAssociation, {
    mutationKey: useDiscontinueWorkerAssociationMutationKey(),
    onSuccess: onMutationSuccess,
  })

const useRegenerateUserQrCodeMutationKey = (): QueryKey => ['users', 'qr-regenerate']
export const useRegenerateUserQrCodeMutation = (): UseMutationResult<string, string, any> =>
  useMutation(regenerateQrCode, {
    mutationKey: useRegenerateUserQrCodeMutationKey(),
    onSuccess: (data, userId) => {
      queryClient.invalidateQueries(['user', userId, 'qr'])
    },
  })

//Auth mutations

export const useIsAuthQueryKey = (): QueryKey => ['users', 'is-auth']
export const useIsAuthQuery = (): UseQueryResult<AuthResponse> =>
  useQuery<any, AxiosError, AuthResponse, QueryKey>(useIsAuthQueryKey(), isAuth, {
    staleTime: 1000 * 60 * 10, // 10 minutes
    onError: onAuthFail,
    onSuccess: data => {
      // Update session data in store
      const { session } = useStore.getState()

      // Get the last version of currently stored user role in API response
      // to update "currentRole" & "currentOrganization" in store
      const currentRole =
        session.currentUserRole &&
        data.user.roles.find(
          (role: UserRole) =>
            role.kind === session.currentUserRole &&
            (role.organization as Organization | undefined)?._id ===
              session.currentOrganization?._id,
        )

      // Get new version of "currentOrganization"
      let currentOrganization = currentRole?.organization as Organization | undefined
      // When current user is Super Admin and "logged as" an organization we keep the stored "currentOrganization"
      // because the current store organization is not necessarily in the API response
      if (session.isSuperAdmin && session.loggedAs?.organization)
        currentOrganization = session.loggedAs?.organization

      // Store session data
      useStore
        .getState()
        .session.initializeSession(
          data.user,
          data.jwt,
          session.currentUserRole,
          currentOrganization,
          currentRole?.organizationRole || 'admin',
        )
    },
  })

const onAuthFail = (data: unknown) => {
  if (axios.isAxiosError(data)) {
    const error = data.toJSON() as AxiosError
    if (
      error.message !== 'Network Error' &&
      error?.response?.data?.message !== 'Version Not Supported'
    ) {
      // Initialize session as guest when auth fail expect the failure is a network error or outdated app version error
      useStore.getState().session.initializeSession()
    }
  }
}

const useAuthMutationKey = (): QueryKey => ['users', 'auth']
export const useAuthMutation = (): UseMutationResult<
  { user: User; jwt: string },
  AuthPayload,
  any
> =>
  useMutation(auth, {
    mutationKey: useAuthMutationKey(),
    onError: onAuthFail,
    onSuccess: ({ data }) => {
      // Store session data on login success

      const activeRoles = data.user?.roles.filter(role => role.status === 'accepted')
      useStore
        .getState()
        .session.initializeSession(
          data.user,
          data.jwt,
          activeRoles[0]?.kind,
          activeRoles[0]?.organization as Organization | undefined,
          activeRoles[0]?.organizationRole,
        )
    },
  })
