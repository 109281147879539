import { Field, Form, Formik } from 'formik'
import React from 'react'
import InputField from '../form/fields/input'
import { useCreateMutation, useUpdateMutation } from '../../queries/job-titles'
import { JobTitle } from '../../api/job-titles'
import SelectField from '../form/fields/select'
import { buildListAsInputOptions } from '../../utils/forms'
import { RequestButton } from '../request-components/request-components'
import TextareaField from '../form/fields/textarea'
import { useClockingRulesQuery } from '../../queries/clocking-rules'
import ClockingRulesDescription from '../clocking-rules/clocking-rules-description'
import { Card, Columns, Element, Heading } from 'react-bulma-components'
import { useAssociatedOrganizationsQuery } from '../../queries/organizations'
import FormField from '../form/fields/form-field'

interface JobTitleFormProps {
  jobTitle?: JobTitle
}

const JobTitleForm: React.FC<JobTitleFormProps> = ({ jobTitle }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const clockingRulesQuery = useClockingRulesQuery()
  const organizationsQuery = useAssociatedOrganizationsQuery()
  const interimAgencies = organizationsQuery.data || []

  if (!organizationsQuery.data) return

  return (
    <Formik
      initialValues={
        jobTitle || {
          name: '',
          description: '',
          complementaryInformations: '',
          clockingRule: '',
          laborCostCoefficients: interimAgencies.map(i => ({ interimAgency: i._id, value: null })),
        }
      }
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (!values.clockingRule) errors.clockingRule = 'Champ requis'
        values.laborCostCoefficients?.forEach((l, index) => {
          if (!l.value) errors[`laborCostCoefficients[${index}].value`] = 'Champ requis'
        })
        return errors
      }}
      onSubmit={values => {
        jobTitle
          ? updateMutation.mutate({ ...values, id: jobTitle._id.toString() })
          : createMutation.mutate(values)
      }}
    >
      {({ values }) => {
        const clockingRules =
          values.clockingRule &&
          clockingRulesQuery.data?.find &&
          clockingRulesQuery.data.find(clockingRules => clockingRules._id === values.clockingRule)

        return (
          <Form autoComplete="off">
            <Heading size={4} mb={5}>
              Informations de base
            </Heading>
            <Field label="Nom " name="name" component={InputField} required />
            <Field name="description" component={TextareaField} label="Description interne" />
            <hr />
            <FormField
              label="Informations complémentaires pour les Travailleurs"
              help="Ces informations seront systématiquement transmises aux Travailleurs recevant une mission pour cet Intitulé de Poste"
            >
              <Field name="complementaryInformations" component={TextareaField} />
            </FormField>
            <Element mb={5} mt={6}>
              <Heading size={5} mb={5}>
                Coefficients de main d'oeuvre
              </Heading>
              <Columns>
                {values.laborCostCoefficients.map((laborCostCoefficient, index) => {
                  const interimAgency = interimAgencies.find(
                    i => i._id === laborCostCoefficient.interimAgency,
                  )
                  if (!interimAgency) return
                  return (
                    <Columns.Column key={interimAgency._id} size={4}>
                      <Element>
                        <Field
                          label={interimAgency.name}
                          name={`laborCostCoefficients[${index}].value`}
                          component={InputField}
                          type="number"
                          required
                          step="0.1"
                        />
                      </Element>
                    </Columns.Column>
                  )
                })}
              </Columns>
            </Element>
            <Element mb={5} mt={6}>
              <Heading size={5} mb={5}>
                Règles de pointage
              </Heading>
              <Field
                name="clockingRule"
                component={SelectField}
                options={
                  clockingRulesQuery.data ? buildListAsInputOptions(clockingRulesQuery.data) : []
                }
              />
              {clockingRules && (
                <>
                  <Card mb={3}>
                    <Card.Content>
                      <Element textWeight="bold" mb={3}>
                        Règle à l'Entrée
                      </Element>
                      <ClockingRulesDescription clockingRules={clockingRules} type="start" />
                    </Card.Content>
                  </Card>
                  <Card mb={3}>
                    <Card.Content>
                      <Element textWeight="bold" mb={3}>
                        Règle à la Sortie
                      </Element>
                      <ClockingRulesDescription clockingRules={clockingRules} type="end" />
                    </Card.Content>
                  </Card>
                </>
              )}
            </Element>
            <RequestButton color="primary" type="submit" mutation={updateMutation} mt={6}>
              {jobTitle ? "Mettre à jour l'Intitulé de Poste" : 'Nouvel Intitulé de Poste'}
            </RequestButton>
          </Form>
        )
      }}
    </Formik>
  )
}
export default JobTitleForm
